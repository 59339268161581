<script>
	import { get, post } from '@/service/api.js'
	import { set } from '@/service/localstorage.js'
	import { router } from '@/service/router.js'

	let name = ''
	let secret = ''
	let requestInFlight
	let sessionToken
	const login = () => {
		requestInFlight = true
		post('/api/login_start', { body: { name } })
			.then(response => {
				sessionToken = response.body?.data?.token
				if (sessionToken) {
					set('session', sessionToken)
					requestInFlight = false
				}
			})
	}
	let retries = 0
	const waitForIt = () => {
		get('/api/me')
			.then(() => {
				router.go('app')
			})
			.catch(() => {
				retries++
				if (retries < 60) setTimeout(waitForIt, 1000)
			})
	}
	const finish = () => {
		if (secret) {
			requestInFlight = true
			post('/api/login_finish', { body: { secret } })
				.then(response => {
					if (response.body?.data?.valid) waitForIt()
				})
		}
	}
</script>

<style>
	img {
		max-width: 8em;
	}
</style>

<div class="container">
	<div class="text-center mt-5">
		<img src="/favicon.png" alt="House Davis (US)">
		{#if requestInFlight && !secret}
			<h1>Check your email</h1>
		{:else if requestInFlight}
			<h1>Just a sec...</h1>
		{:else}
			<h1>Hello</h1>
		{/if}
	</div>
	{#if !requestInFlight}
		{#if sessionToken}
			<form on:submit|preventDefault={finish} style="max-width: 15em; margin: 0 auto; text-align: center;">
				<label for="secret" class="form-label" style="width: 100%; text-align: center;">What's the suuuuuuuper secret password from the email?</label>
				<input type="text" class="form-control mb-3" id="secret" bind:value={secret}>
				<button disabled={!secret} type="submit" class="btn btn-primary">Get to the Good Stuff ✨</button>
			</form>
		{:else}
			<form on:submit|preventDefault={login} style="max-width: 15em; margin: 0 auto; text-align: center;">
				<label for="name" class="form-label" style="width: 100%; text-align: center;">What's your name?</label>
				<input type="text" class="form-control mb-3" id="name" bind:value={name}>
				<button type="submit" class="btn btn-primary">Let me innnnnnn</button>
			</form>
		{/if}
	{/if}
</div>
