export const navLinks = (asr, currentUser) => {
	const stateName = asr.getActiveState().name
	const isParent = [ 'tobias', 'claire' ].includes(currentUser?.name?.toLowerCase())
	return [
		{
			title: 'Chat 💬',
			name: 'app.chat',
		},
		isParent && {
			title: 'Spending 💸',
			name: 'app.spending',
		},
		isParent && {
			title: 'Budget 💰',
			name: 'app.budget',
		},
	].filter(Boolean).map(link => {
		link.active = link.name === stateName || stateName.startsWith(link + '.')
		return link
	})
}
