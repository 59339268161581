<script>
	import { put } from '@/service/api.js'

	export let budget // = { Groceries: 12.34 }
	export let original

	let saving
	let success
	let error
	let name

	$: changes = Object.keys(original).find(key => budget[key] !== original[key])
		|| Object.keys(budget).find(key => budget[key] !== original[key])

	const addCategory = () => {
		budget[name] = 0
		name = ''
	}
	const removeCategory = cat => () => {
		delete budget[cat]
		budget = { ...budget }
	}
	const save = () => {
		saving = true
		success = null
		put('/api/budget', { body: { data: budget } })
			.then(() => { success = true })
			.catch(e => { error = e })
	}
</script>

<div class="container mt-3">
	<h3>Budget Categories</h3>
	<p>Monthly amounts per category.</p>
	{#each Object.keys(budget) as ba}
		<div class="row align-items-center mb-3">
			<div class="col">
				<label for={ba} class="form-label">
					{ba}
				</label>
			</div>
			<div class="col">
				<input
					disabled={saving}
					type="number"
					min="1"
					step="any"
					class="form-control"
					id={ba}
					bind:value={budget[ba]}
					on:input={() => { success = null }}
				>
			</div>
			<div class="col-auto">
				<button class="btn btn-sm btn-outline-danger" on:click={removeCategory(ba)}>X</button>
			</div>
		</div>
	{/each}

	<hr>

	<button disabled={saving || !changes} type="submit" class="btn btn-primary mb-3" on:click={save}>
		Save Changes
	</button>
	{#if success}
		<br>
		<p><small>✅ Saved</small></p>
	{/if}

	<br>
	<label for="add-category" class="form-label">
		Name
	</label>
	<input
		type="text"
		class="form-control mb-3"
		id="add-category"
		bind:value={name}
	>
	<button class="btn btn-sm btn-secondary" on:click={addCategory}>
		Add Category
	</button>
</div>
