<script>
	export let asr

	const nav = [
		{
			path: 'app.spending.spend',
			label: 'Make a Transaction',
		},
		{
			path: 'app.spending.history',
			label: 'Past Spending',
		},
	]
</script>

<div class="container mt-3 mb-3">
	<ul class="nav nav-pills">
		{#each nav as { path, label }}
			<li class="nav-item">
				<a class="nav-link" href={asr.makePath(path, null, { inherit: false })} class:active={asr.getActiveState().name === path}>
					{label}
				</a>
			</li>
		{/each}
	</ul>
</div>

<uiView />
