import { router } from '@/service/router.js'

import login from '@/route/login/+page.js'
import app from '@/route/app/+page.js'
import budget from '@/route/app/budget/+page.js'
import chat from '@/route/app/chat/+page.js'
import dashboard from '@/route/app/dashboard/+page.js'
import spending from '@/route/app/spending/+page.js'
import spending_spend from '@/route/app/spending/spend/+page.js'
import spending_history from '@/route/app/spending/history/+page.js'
const routes = [
	[ 'login', login ],
	[ 'app', app ],
	[ 'app.budget', budget ],
	[ 'app.chat', chat ],
	[ 'app.dashboard', dashboard ],
	[ 'app.spending', spending ],
	[ 'app.spending.spend', spending_spend ],
	[ 'app.spending.history', spending_history ],
]

for (const [ name, state ] of routes) {
	state.name = name
	state.route = name.split('.').pop()
	router.addState(state)
}

router.on('routeNotFound', (...args) => { console.error('routeNotFound', ...args) })
router.on('stateError', error => { console.error('stateError', error) })
router.on('stateChangeError', error => {
	console.error('stateChangeError', error)
	if (error.status === 401) router.go('login')
	else console.error('main:stateChangeError', error)
})

router.on('stateChangeEnd', state => {
	// progress.complete()
	// if (state.data && state.data.title) window.document.title = `MCS OnTrack | ${state.data.title}`
})

router.on('stateChangeStart', (state, parameters) => {
	// if (progress) progress.start()
	window.scrollTo(0, 0)
})

router.evaluateCurrentRoute('login')
