<script>
	import { post } from '@/service/api.js'

	export let budget

	let amount
	let payee = ''
	let narration = ''
	let account
	let posting
	let success
	let error
	const postTransaction = () => {
		posting = true
		post('/api/transactions', { body: { data: { amount, payee, narration, account } } })
			.then(() => { success = true })
			.catch(e => { error = e })
	}

	$: disabled = posting
</script>

<div class="container">
	<form on:submit|preventDefault={postTransaction}>

		<label for="amount" class="form-label">Amount</label>
		<input {disabled} type="number" min="1" step="any" class="form-control mb-3" id="amount" bind:value={amount}>

		<label for="payee" class="form-label">To Who <small>(e.g. Target)</small></label>
		<input {disabled} type="text" class="form-control mb-3" id="payee" bind:value={payee}>

		<label for="narration" class="form-label">For What</label>
		<input {disabled} type="text" class="form-control mb-3" id="narration" bind:value={narration}>

		<label for="account" class="form-label">Mostly For</label>
		<div class="list-group mb-3">
			{#each Object.keys(budget) as ba}
				<button
					{disabled}
					type="button"
					class="list-group-item list-group-item-action"
					class:active={ba === account}
					on:click={() => { account = ba }}
				>
					{ba}
				</button>
			{/each}
		</div>

		<hr>

		<button disabled={posting || !amount || !payee || !narration || !account} type="submit" class="btn btn-primary mb-3">Post Transaction</button>
		{#if success}
			<h2>Done ✅</h2>
			<p>
				You could, like,
				<a href="/#/app/spend" on:click={() => { window.location.reload() }}>make a new transaction</a>
				or go to the
				<a href="/">home page</a>
				I guess?
			</p>
		{/if}
	</form>
</div>
