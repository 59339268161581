import template from './+page.svelte'
import { get } from '@/service/api.js'

export default {
	template,
	resolve: async () => {
		const me = await get('/api/me').catch(_ => _)
		if (me?.body?.data) return Promise.reject({
			redirectTo: { name: 'app' },
		})
	},
}
