<script>
	import { navLinks } from '@/lib/nav-links.js'

	export let asr
	export let currentUser

	$: links = navLinks(asr, currentUser)
</script>

<svelte:head>
	<style>
		body { min-height: 100vh; }
	</style>
</svelte:head>

<div class="container-xs bg-body-tertiary" style="height: 100vh; display: flex; flex-direction:column;">
	<nav class="navbar navbar-expand-xs bg-body">
		<div class="container-fluid">
			<a class="navbar-brand" href="/">
				<img src="/favicon.png" alt="House Davis (US)" style="width: 2em;">
			</a>
			<ul class="nav me-auto">
				{#each links as { title, name, active }}
					<li class="nav-item">
						<a
							class="nav-link"
							class:active
							aria-current="page"
							href={asr.makePath(name)}
						>
							{title}
						</a>
					</li>
				{/each}
			</ul>
		</div>
	</nav>
	<div class="bg-body-tertiary" style="flex: 1; overflow-y: scroll" id="main-app-scroll">
		<uiView />
	</div>
</div>
