<script>
	import Transaction from './Transaction.svelte'
	import { onMount } from 'svelte'
	import { get } from '@/service/api.js'

	let transactions

	onMount(() => {
		get('/api/transactions?foo=yes').then(({ body: { data, more, cursor } }) => {
			transactions = [ ...(transactions || []), ...data ].sort((a, b) => {
				return new Date(a.date).getTime() - new Date(b.date).getTime()
			})
		})
	})
</script>

<div class="container">
	{#if !transactions}
		<p class="text-center my-3 text-muted">Loading...</p>
	{:else}
		{#each transactions as { date, amount, payee, narration, account }}
			<div class="card mb-2">
				<div class="card-body py-2 px-3">
					<Transaction {date} {amount} {payee} {narration} {account} />
				</div>
			</div>
		{/each}
	{/if}
</div>
