<script>
	import { onMount } from 'svelte'
	import Sockette from 'sockette'
	import { PaperPlane } from 'radix-icons-svelte'
	import { post } from '@/service/api.js'
	import ChatMessage from './ChatMessage.svelte'

	export let currentUser

	const stateToLabel = {
		closed: '⚠️ Connection closed, retrying...',
		connecting: '🤔 Connecting, one sec...',
		error: '⚠️ Error, retrying...',
		fail: '🚨 Failed to connect!',
		open: '🟩 Connected',
	}

	const messageTree = {}
	let ws
	let state = 'connecting'
	onMount(() => {
		const { host } = window.location
		const url = host && host.includes('localhost')
			? `ws://${host}/ws`
			: `wss://${host}/ws`
		ws = host && new Sockette(url, {
			timeout: 5e3,
			maxAttempts: 10,
			onopen: () => {
				state = 'open'
				ws.json({ ping: Date.now() })
			},
			onmessage: event => {
				try {
					const message = JSON.parse(event.data)
					if (message.date) {
						messageTree[message.date] = message
						setTimeout(() => {
							const elem = document.getElementById('main-app-scroll')
							elem.scrollTo({ top: elem.scrollHeight, behavior: 'smooth' })
						})
					}
				} catch (error) {
					console.log('Unexpected message data:', event.data)
				}
			},
			onerror: e => {
				state = 'error'
				console.error('Error connecting to WS:', e)
			},
			onreconnect: () => { state = 'connecting' },
			onmaximum: () => { state = 'fail' },
			onclose: () => { state = 'closed' },
		})
	})

	let growWrap
	let message = ''
	let sending = []
	const sendMessage = () => {
		const msg = {
			message,
			name: currentUser.name.toLowerCase(),
			sending: true,
		}
		setTimeout(() => {
			const elem = document.getElementById('main-app-scroll')
			elem.scrollTo({ top: elem.scrollHeight, behavior: 'smooth' })
		})
		post('/api/chats', { body: { data: { message } } })
			.then(({ body: { data } }) => {
				sending = [ ...sending.filter(m => m !== msg) ]
				messageTree[data.date] = data
			})
			.catch(error => {
				msg.error = error
			})
		message = ''
		growWrap.dataset.replicatedValue = ''
	}

	$: messages = Object.keys(messageTree).sort().map(k => messageTree[k])
</script>

<style>
	/*
	https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
	*/
	.grow-wrap {
		display: grid;
	}
	.grow-wrap::after {
		content: attr(data-replicated-value) " ";
		white-space: pre-wrap;
		visibility: hidden;
	}
	.grow-wrap > textarea {
		resize: none;
		overflow: hidden;
	}
	.grow-wrap > textarea,
	.grow-wrap::after {
		grid-area: 1 / 1 / 2 / 2;
		/* Identical styling required!! */
		border: none;
		padding: 0.1em 0.4em;
		margin: 0 0 0.05em 0.2em;
		font: inherit;
		font-size: 1.2em;
	}
	textarea:focus {
		outline: none;
	}
</style>

<div class="container">
	{#if !messages?.length}
		<p>No messages yet. Send one?</p>
	{/if}
	{#each [ ...(messages || []), ...(sending || []) ] as { date, message, name, sending, pictures }, index}
		{#if date && messages && messages[index - 1]?.date && new Date(date).toLocaleDateString(undefined, { day: 'numeric' }) !== new Date(messages[index - 1].date).toLocaleDateString(undefined, { day: 'numeric' })}
			<p class="mb-1">
				<small>
					{new Date(date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}
				</small>
			</p>
		{/if}
		<ChatMessage fromSelf={currentUser.name.toLowerCase() === name} {date} {message} {name} {sending} {pictures} />
	{/each}
</div>

<div class="sticky-bottom">
	{#if stateToLabel[state]}
		<p class="m-0 p-1" style="opacity: 40%; font-size: 0.6em;">
			{stateToLabel[state]}
		</p>
	{/if}
	<div class="row align-items-center g-0 pb-3 bg-body">
		<div class="col grow-wrap" bind:this={growWrap}>
			<textarea placeholder="Type a message" rows="1" bind:value={message} oninput="this.parentNode.dataset.replicatedValue = this.value"></textarea>
		</div>
		<div class="col-auto">
			<button class="btn btn-link" disabled={!message} on:click={sendMessage}>
				<span class="p-1" style="position: relative; top: -2px;">
					<PaperPlane size="20" />
				</span>
			</button>
		</div>
	</div>
</div>
