<script>
	export let date
	export let amount
	export let payee
	export let narration
	export let account

	let dollars
	let cents
	$: {
		[ dollars, cents ] = (amount || 0).toString().split('.')
		cents = (cents || '').padEnd(2, '0')
	}
</script>

<div class="row">
	<div class="col-auto pt-1">
		{new Date(date).toLocaleDateString()}
	</div>
	<div class="col">
		<span class="fs-4">
			{payee}
		</span>
		{#if narration}
			<br>
			{narration}
		{/if}
	</div>
	<div class="col-auto text-end" style="width: 12ch;">
		<span class="fs-4">${dollars}</span>.<span>{cents}</span>
		{#if account}
			<br>
			{account}
		{/if}
	</div>
</div>
