import template from './+page.svelte'
import { get } from '@/service/api.js'
export default {
	template,
	resolve: async () => {
		const { body: { data: budget } } = await get('/api/budget')
		return {
			budget,
			original: JSON.parse(JSON.stringify(budget)),
		}
	},
}
