<script>
	import { navLinks } from '@/lib/nav-links.js'

	export let asr
	export let currentUser

	$: links = navLinks(asr, currentUser)
</script>

<ul class="list-group text-center mt-5">
	{#each links as { title, name }}
		<a href={asr.makePath(name)} class="list-group-item list-group-item-action">
			{title}
		</a>
	{/each}
</ul>
