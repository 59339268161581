<script>
	export let fromSelf
	export let date
	export let message
	export let name
	export let sending
	const nameToEmoji = {
		tobias: '🧔‍♂️',
		claire: '👩',
		willa: '👧',
		martin: '👦',
	}
	$: sent = new Date(date).toLocaleTimeString(undefined, {
		// year: 'numeric',
		// month: 'long',
		// day: 'numeric',
		timeStyle: 'short',
	})
</script>

<style>
	img {
		width: 2.6em;
		border: 1px solid black;
		border-radius: 100px;
	}
	small {
		font-size: .675em;
	}
	div.connector {
		width: 0.6em;
		height: 2px;
		background-color: #ddd;
		margin-top: 1.2em;
	}
</style>

<div class="row g-0" class:justify-content-end={fromSelf}>
	<div class="col-auto" class:order-2={fromSelf}>
		<img src="/image/profile-{name}.png" alt={name}>
	</div>
	<div class="col-auto connector" class:order-1={fromSelf}></div>
	<div class="col">
		<div class="card">
			<div class="card-body px-3 py-2">
				{message}
			</div>
		</div>
		<p>
			<small class="text-muted">
				{#if sending}
					Sending...
				{:else}
					{sent}
				{/if}
			</small>
		</p>
	</div>
</div>
