import template from './+page.svelte'
import { get } from '@/service/api.js'

export default {
	template,
	defaultChild: 'dashboard',
	resolve: async () => {
		const me = await get('/api/me')
		return { currentUser: me.body.data }
	},
}
